import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Nav: React.FC = () => {
  return (
    <StyledNav>
      <StyledLink exact activeClassName="active" to="/">Home</StyledLink>
      <StyledLink exact activeClassName="active" to="/swap">Swap</StyledLink>
      <StyledLink exact activeClassName="active" to="/bonds">Bonds</StyledLink>
      <StyledLink exact activeClassName="active" to="/liquidity">Liquidity</StyledLink>
      <StyledLink exact activeClassName="active" to={{pathname: "http://docs.theanthill.io"}} target="_blank">Docs</StyledLink>
      <StyledLink exact activeClassName="active" to="/help">Help</StyledLink>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.color.grey[400]};
  font-weight: 700;
  padding-left: ${props => props.theme.spacing[3]}px;
  padding-right: ${props => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.color.grey[500]};
  }
  &.active {
    color: ${props => props.theme.color.primary.main};
  }
`

export default Nav