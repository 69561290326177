export const white = '#FFF'
export const black = '#000'

export const green = {
  500: '#00D110',
}

export const purple = {
  200: '#bd7cfe',
  400: '#ab47bc',
  700: '#7b1fa2',
}

export const red = {
  100: '#C1C1FF',
  200: '#7575FF',
  500: '#77e463',
}

export const grey = {
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575', 
  700: '#616161',
  800: '#424242',
  900: '#212121',
}

export const bg = '#171615';

export const teal = {
  200: '#61b551'
}